import React from 'react';


import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import VideoBg from '../src/assets/video.mp4';

import './styles.css';

const App = () => {
  return <section className='page'>
    <div className='overlay'></div>
    <video src={VideoBg} autoPlay loop muted></video>

    <div className='page__content'>
      <h1>Launching Soon</h1>
      <h3>Get your PSYOP for fun</h3>


      <FlipClockCountdown
        // to={new Date().getTime() + 24 * 3600 * 1000 + 5000}
        to={1704585600000}
        className='flip-clock'
        labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
        // labelStyle={{ fontSize: 10, fontWeight: 500, textTransform: 'uppercase' }}
        // digitBlockStyle={{ width: 40, height: 60, fontSize: 30 }}
        // dividerStyle={{ color: 'white', height: 1 }}
        // separatorStyle={{ color: 'red', size: '6px' }}
        duration={0.5}
      >
        Finished
      </FlipClockCountdown>

      {/* <button className='btn'>Notify me</button> */}
    </div>


  </section>;
};

export default App;
